<template>
    <div>
        <b-card no-body class="mx-auto" style="max-width: 420px">
            <b-card-header>
                الأسئلة الواردة {{ detailsVideoSelectedIndex >= 0 ? '- ' + lessonDto.lessonVideos[detailsVideoSelectedIndex].name : ''}}
            </b-card-header>
            <b-card-body class="mb-1" v-if="detailsVideoSelectedIndex >= 0">
                <div class="d-flex justify-content-between pb-2">
                    <span>عرض المجاب فقط</span>
                    <b-form-checkbox
                        class="custom-control-primary"
                        style="margin-left: -16px"
                        switch
                        v-model="jostAnswer"
                    />
                </div>
                <b-card-body
                    class="border rounded px-2 pt-2 pb-0 mb-1"
                    v-for="(question, index) in questionsActivePage" :key="index"
                >
                    <div class="d-flex justify-content-between">
                        <strong>
                            <unicon name="box" fill="#5E5873"></unicon>
                            {{ new Date(question.dateAsked).toISOString().substr(0, 19).replace("T"," / ") }}
                        </strong>
                        <b-badge style="padding: 6px 12px!important" :variant="question.responderId ? 'light-success' : 'light-warning'" v-text="question.responderId ? 'مجاب' : 'غير مجاب'"></b-badge>
                    </div>
                    <div class="d-flex pt-2">
                        <unicon name="question-circle" class="pr-1" fill="#5E5873"></unicon>
                        <p class="m-0">
                            {{
                                question.question.substr(0, 80) + (question.question.length > 80 ? '...' : '')
                            }}
                        </p>
                    </div>
                    <b-button block variant="white" :to="`/lessons/${id}/${videoId}/question/${question.responderId ? '' : 'un/'}${question.id}`"> عرض التفاصيل <unicon name="angle-left" fill="#5E5873"></unicon></b-button>
                </b-card-body>
                <b-col cols="12" class="d-flex justify-content-center">
                    <b-pagination
                        :value="questionsFirstPage"
                        :total-rows="filterdQuestions.length"
                        :per-page="questionsPageLength"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mt-1 mb-0"
                        @change="(value) => updateVideoQuestionsPagination(value)"
                    >
                        <template #prev-text>
                            <unicon width="20" name="angle-right" fill="royalblue" />
                        </template>
                        <template #next-text>
                            <unicon width="20" name="angle-right" fill="royalblue" />
                        </template>
                    </b-pagination>
                </b-col>
            </b-card-body>
            <b-card-body class="text-center" v-else>
                <h5>اختر ملف لعرض الأسئلة</h5>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
import {
    BCard,
    BCardHeader,
    BCardBody,
    BFormCheckbox,
    BBadge,
    BButton,
    BPagination,
    BCol
} from "bootstrap-vue"
import { mapActions, mapGetters } from 'vuex'
export default {
    props: {
        id: String,
        videoId: String
    },
    data: () => ({
        jostAnswer: false
    }),
    components: {
        BCard,
        BCardHeader,
        BCardBody,
        BFormCheckbox,
        BBadge,
        BButton,
        BPagination,
        BCol
    },
    mounted() {
        this.getVideoDetils(this.videoId)
    },
    computed: {
        ...mapGetters(['lessonDto', 'filterdQuestions', 'detailsVideoSelectedIndex', 'questionsPageLength', 'questionsActivePage', 'questionsFirstPage'])
    },
    methods: {
        ...mapActions(['getVideoDetils', 'updateVideoQuestionsPagination', 'filterQuestions'])
    },
    watch: {
        videoId(id){
            this.getVideoDetils(id)
            this.jostAnswer = false;
        },
        jostAnswer(isJostAnswer) {
            this.filterQuestions(!isJostAnswer)
        }
    }
}
</script>